import React from "react"
import PageHeader from "../components/PageHeader/PageHeader"
import Citation from "../components/Citation/Citation"
import "./contact.css"
import ContactForm from "../components/ContactForm/ContactForm"
import PageFooter from "../components/PageFooter/PageFooter"
import {graphql, useStaticQuery} from "gatsby"
import Seo from "../components/seo"

const Contact = () => {
  const {sanityContactpage} = useStaticQuery(query)
  const {_rawCitation, Header} = sanityContactpage
  return (
    <div style={{paddingTop: "100vh"}}>
      <Seo title="Contact"/>
      <PageHeader
        media={Header}
        titre={"Contact"}
        sousTitre={"Accueil • Contact"}
      />
      <div className="contact__citation">
        <Citation text={_rawCitation}/>
      </div>
      <ContactForm/>
      <PageFooter/>
    </div>
  )
}

const query = graphql`
    query {
        sanityContactpage {
            Header {
                ...HeaderImage
                ...HeaderYoutubeVideo
            }
            _rawCitation
        }
    }
`

export const Head = () => <Seo title="Contact"/>

export default Contact
