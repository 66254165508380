import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toast";
import "./style.css";
import Button from "../Button/Button";

const postClientMessage = (
  firstname,
  surname,
  email,
  phone,
  subject,
  message
) => {
  axios
    .post(process.env.STRAPI_API_URL + "api/client-messages", {
      data: { firstname, surname, email, phone, subject, message }
    })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
};

const ContactForm = () => {
  // get current url
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setCurrentUrl(window.location.href);
    // get url params
    const urlParams = new URLSearchParams(window.location.search);
    // if success param is present, show success message
    const success = urlParams.get("success");
    console.log(success);
    if (success) {
      toast.success("Message envoyé!");
    }
  }, []);
  return (
    <div className="contactForm__container">
      <form className="contactForm" action="https://formsubmit.co/contact@felixbrossard.com" method="POST">
        <input type="hidden" name="_next" value={currentUrl+"?success=1"} />
        <input type="hidden" name="_subject" value="Site Web - Nouveau Message client" />
        <div className="row space-between wrap">
          <input name="surname" id="surname" type="txt" placeholder="Nom" />
          <input
            name="firstname"
            id="firstname"
            type="text"
            placeholder="Prénom"
          />
        </div>
        <div className="row space-between wrap">
          <input name="email" id="email" type="text" placeholder="Email" />
          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="Téléphone"
          />
        </div>
        <input name="subject" id="subject" type="text" placeholder="Objet" />
        <textarea name="message" id="message" placeholder="Votre message" />
        <Button
          text={"Envoyer"}
        />
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
