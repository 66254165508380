import React from "react"
import "./style.css"
import corner from "../../images/citation_corner.svg"
import ReactMarkdown from "react-markdown"
import {PortableText} from "@portabletext/react";

const Citation = ({ text, theme = "light" }) => {
  return (
    <table className={"citation__table"}>
      <tr>
        <td>
          <img src={corner} alt={""} width={35} height={35} />
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div
            className={
              theme === "light"
                ? "citation__inside lightQuote"
                : "citation__inside darkQuote"
            }
          >
            {
              typeof text === "string" ? (
                <>{text}</>
              ) : (
                <PortableText value={text} />
              )
            }
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>
          <img
            src={corner}
            alt={""}
            width={35}
            height={35}
            className={"reversed-corner"}
          />
        </td>
      </tr>
    </table>
  )
}

export default Citation
